import * as React from "react";
import { useNavigate } from "react-router";


function DiscoverMoreButton() {
  const navigate = useNavigate();

  return (
    <button onClick={()=>navigate('/sustainability')} className=" flex justify-center items-center gap-4  px-6 py-3 mt-8 max-w-full text-base text-center text-[#F1891E] bg-white w-[212px] max-md:px-5">
     
      <span className="">Discover More</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="37" height="16" viewBox="0 0 37 16" fill="none">
  <path d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z" fill="#F1891E"/>
</svg>
    </button>
  );
}

function SustainabilityRight() {
  return (
    <section
      className="flex flex-col px-8  text-white w-full max-h-[529px] max-md:px-5 h-full "
      style={{
        background: " linear-gradient(36deg, #F08110 36.93%, #F3B677 98.7%)",
      }}
    >
      <h1 className="mt-24 text-xl font-black uppercase max-md:mt-10 max-md:max-w-full">
        SUSTAINING SUSTAINABILITY
      </h1>
      <p className="mt-7 text-xl leading-9 max-md:max-w-full hidden_scrollbar">
        Explore how we're intertwining profitability with responsibility,
        fostering a harmonious relationship between commerce, environmental
        responsibility and social equity. Together, let us lay the foundation
        for a more sustainable and prosperous present while creating positive
        changes for the generations to come.
      </p>
      <DiscoverMoreButton />
    </section>
  );
}

export default SustainabilityRight;
