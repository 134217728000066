import * as React from "react";

const countries = [
  {
    title: 'INDIA',
    flagUrl: 'https://www.worldometers.info/img/flags/in-flag.gif',
    locations: [
      {
        country: 'TAMIL NADU',
        company: 'Nirantara Ecoventures Pvt Ltd',
        address:
          'New No.16 Old No.18, Chengalvarayan Street, Shenoy Nagar, Egmore Nungambakkam, Chennai- 600030, Tamil Nadu, India',
      },
      {
        country: 'MAHARASHTRA',
        company: 'Nirantara Ecoventures Pvt Ltd',
        address:
          'Office no. 405, 4th Floor, Tower 2, World Trade Center, Kharadi, Pune – 411014, Maharashtra, India',
      },
    ],
  },
  {
    title: 'UAE',
    flagUrl: 'https://www.worldometers.info/img/flags/ae-flag.gif',
    locations: [
      {
        country: 'UNITED ARAB EMIRATES',
        company: 'Nirantara Sustainable Consultancy LLC',
        address:
          '1513, Grosvenor Business Tower, Al Barsha Heights, Dubai, P.O.Box 454352 United Arab Emirates',
      },
    ],
  },
  {
    title: 'SINGAPORE',
    flagUrl: 'https://www.worldometers.info/img/flags/sn-flag.gif',
    locations: [
      {
        country: 'SINGAPORE',
        company: 'Nirantara Sustainable Ventures Pte Ltd',
        address:
          '30, Cecil Street, # 19-08, Prudential Tower Singapore (049712)',
      },
    ],
  },
]


function LocationCard({ location }) {
  return (
    <article className="flex flex-col grow p-8 w-full text-lg font-semibold leading-9 bg-white rounded-lg shadow-lg max-md:px-5 max-md:mt-5 max-md:max-w-full">
      <h3 className="font-medium text-blue-800 max-md:max-w-full">{location.country}</h3>
      <h2 className="mt-6 text-3xl text-black capitalize max-md:max-w-full">{location.company}</h2>
      <p className="mt-6 text-black max-md:max-w-full font-normal grow">{location.address}</p>
      <a href="mailto:sustainable@nirantara.solutions" className="justify-center self-start px-6 py-3 mt-6 text-base text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-sm max-md:px-5">
        Contact
      </a>
    </article>
  );
}

function Content() {
  return (
    <main className='flex flex-col max-w-7xl '>
      {countries.map(({locations,...country}) => (
        <section className='justify-center w-full md:px-0 px-4 max-md:max-w-full'>
          <div className="lg:ml-8 flex gap-2 items-center text-lg font-bold md:py-6 py-3">
            <h2>{country.title}</h2> 
            <img
              loading="lazy"
              src={country.flagUrl}
              alt={country.title}
              className="object-cover w-6 border border-black shadow-md"
            />
          </div>
          <div className='flex gap-5 max-md:flex-col max-md:gap-0'>
            {locations.map((location, index) => (
              <div
                key={index}
                className='flex flex-col w-6/12 max-md:ml-0 max-md:w-full'
              >
                <LocationCard location={location} />
              </div>
            ))}
          </div>
        </section>
      ))}
    </main>
  )
}

export default Content;