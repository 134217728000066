import React from "react";
import Landing from "./Landing";
import JobOpenings from "./JobOpenings";
import Process from "./Process";

const Carrer = () => {
  return (
    <div className="w-full md:mb-20 mb-8 flex flex-col items-center gap-8 montserrat">
      <Landing />
      {/* <div className="w-full px-4 md:px-16 text-left mt-8">
        <h1 className="text-black lg:text-5xl font-semibold uppercase helvetica mb-8">
          INTRODUCTION
        </h1>

        <h2 className="text-2xl md:text-3xl font-bold text-gray-800">
          BE THE FORCE OF CHANGE THAT MAKES AN IMPACT
        </h2>

        <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
          At Nirantara, work is more than just a task; it’s a journey of growth and impact. 
          Our strong work ethic and robust processes provide a deep sense of satisfaction, 
          knowing we contribute to the global sustainability agenda.
        </p>
        <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
          We are building a robust resource base to support enterprises across India’s 
          industrial belts, fostering impactful change while advancing careers in the 
          fast-evolving field of ESG and sustainability.
        </p>
        <h3 className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
          Join us to embark on a rewarding journey towards building a sustainable future.
        </h3>
      </div> */}
      <JobOpenings />
      <Process />
    </div>
  );
};

export default Carrer;
