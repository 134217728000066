import * as React from "react";
import TrainingExpansion from "./TrainingExpansion.js"
import CollapseButton from "./CollapseButton.js";

function Training({expanded, setExpanded}) {
  const pageName = 'Training'
  const isExpanded = expanded === pageName 
  const toggleExpand = () => {
    setExpanded(isExpanded ? null : pageName)
  }

  return (
    <section className="flex flex-col justify-center text-center text-white w-full">
      <div className="flex overflow-hidden relative flex-col justify-center items-start px-16 py-20 w-full min-h-[593px] max-md:px-5 max-md:max-w-full">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/768653de4a9036f859b707002931a89034e9d94add89fc42496412cb20aa45a5?apiKey=b3217365a35d45c196e47ed20ac8b87c&" alt="" className="object-cover absolute inset-0 size-full" />
        <div className="flex relative flex-col items-start py-11 pr-20 pl-10 mt-14 mb-8 ml-3.5 max-w-full rounded-lg shadow-lg backdrop-blur-[400px] w-[600px] max-md:px-5 max-md:mt-10">
        <div className="text-xl font-medium">03/ NIRANTARA PRODUCTS</div>
          <h2 className="mt-8 lg:text-4xl font-bold uppercase">TRAINING</h2>
          <button onClick={toggleExpand} className="flex gap-5 justify-between px-6 py-3 mt-28 text-base text-black capitalize whitespace-nowrap bg-white rounded-sm max-md:px-5 max-md:mt-10">
            <span>{!isExpanded ? 'Expand' : 'Collapse'}</span>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6106783f798b3e6a89a9f1bdfcda0d1d63394178e9b794f9eb148def2d7a07d9?apiKey=b3217365a35d45c196e47ed20ac8b87c&"
              alt="Expand icon"
              className="shrink-0 my-auto w-4 aspect-square"
            />
          </button>
        </div>
      </div>
      {
        isExpanded && 
          <>
            <TrainingExpansion />
            <CollapseButton onClick={toggleExpand} />
          </>
      }
    </section>
  );
}

export default Training;