import React from 'react';

function PDFs() {
  const pdfFiles = [
    'certification.pdf',
    // Add more PDF file names as needed
  ];

  return (
    <div className="flex flex-wrap  gap-6 px-6 py-6">
      
      {pdfFiles.map((pdf, index) => (
        <div key={index} className="border border-gray-300 rounded-lg hidden_scrollbar overflow-hidden">
          <embed
            src={`/assets/${pdf}`} 
            type="application/pdf"
            width="320" 
            height="415" 
          />
        </div>
      ))}
    </div>
  );
}

export default PDFs;
