import * as React from "react";


function Landing() {
  return (
    <div className="flex flex-col justify-center text-white w-full  ">
      <div className="flex overflow-hidden relative flex-col items-center px-16 pt-6 pb-14 w-full banner_container lg:min-h-[calc(100vh-100px)] _mobile_banner max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0eae54da57105ec4d3e3e44db6a080ad8c44c5a73a99f737c46aff677e8ebe6e?apiKey=b3217365a35d45c196e47ed20ac8b87c&"
          alt="Background"
          className="object-cover absolute inset-0 lg:size-full"
        />
        <div className="flex  mt-40 md:mt-0 absolute lg:bottom-8 lg:left-12 max-w-[90%] flex-col w-full  max-md:max-w-full">
          <main>
            <h1 className=" left-0 text-7xl _journey_container font-black uppercase lg:mt-10 max-md:text-4xl">
              Sustaining Tomorrow, One Step at a Time: Nirantara Journey
            </h1>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Landing;
