import * as React from 'react'
function ESGServiceItem({ title, items }) {
  return (
    <div className='w-full text-lg text-black max-md:max-w-full  md:mb-4 mt-2'>
      {' '}
      <span className='text-xl font-semibold leading-6 text-black'>
        {title}
      </span>{' '}
      <ul className='px-8 list-disc '>
        {items.map((item, index) => (
          <li key={index}>
            <span className=' font-normal text-lg leading-9 text-black'>
              {item}
            </span>{' '}
          </li>
        ))}{' '}
      </ul>{' '}
    </div>
  )
}
function DeliverablesExpansion() {
  const esgServices = [
    {
      title: '1. ESG Strategy Development',
      items: [
        'Assessment and Materiality Analysis: Begin with a thorough assessment of current practices and a materiality analysis to identify key ESG areas that align with business goals and stakeholder interests.',
        'Crafting comprehensive ESG strategies aligned with business goals.',
        'Integration of ESG principles into corporate strategy and operations.',
        'Identifying and quantifying ESG-related risks, including regulatory, reputational, and financial risks, to inform risk management strategies.',
      ],
    },
    {
      title: '2. Sustainability Reporting',
      items: [
        'Assistance in creating sustainability reports following standards like BRSR, GRI, SASB, ECOVADIS, CDP and TCFD.',
        'Development of carbon footprint reports and other environmental impact assessments.',
      ],
    },
    {
      title: '3. ESG Due Diligence',
      items: [
        'Conducting due diligence for mergers, acquisitions, and investments from an ESG perspective (ESMS / ESAPS / SAQ).',
        'Identifying ESG risks and opportunities in potential investments.',
      ],
    },
    {
      title: '4. ESG Compliance and Risk Management',
      items: [
        'Guidance on compliance with local and international ESG regulations.',
        'Identifying and managing ESG-related risks.',
      ],
    },
    {
      title: '5. Environmental Consulting',
      items: [
        'Carbon footprinting. Calculation of greenhouse gas (GHG) emissions across different scopes (Scope 1, 2, and 3) to identify major sources of emissions and opportunities for reduction',
        'Product Life Cycle Assessment (LCA) calculation',
        "Water Footprint Assessment - Quantifying water usage and efficiency within an organization's operations and supply chain to identify conservation opportunities",
        'Waste Management Analysis - Measuring waste generation and diversion rates to improve waste management practices, including reduction, recycling, and reuse strategies.',
        'Biodiversity impact assessments. - Quantifying the impact of operations on local and global biodiversity to develop strategies for conservation and positive contributions to ecosystems.',
        'Energy Efficiency Analysis - Assessing energy consumption patterns to identify efficiency improvements and opportunities for transitioning to renewable energy sources.',
      ],
    },
    {
      title: '6. Supply Chain Sustainability Assessment',
      items: [
        'Measuring the sustainability performance of suppliers and the supply chain to identify risks and opportunities for improvement',
      ],
    },
    {
      title: '7. Social Impact Assessments',
      items: [
        'Evaluating the social impact of operations, including labor practices, community engagement, and human rights issues.',
        'Developing strategies to enhance social well-being and workforce diversity and inclusion (DEI).',
        'Capacity Building, Qualitative Monitoring and Evaluation',
        'Social, Health and Safety Programs',
      ],
    },
    {
      title:
        '8. Corporate Social Responsibility (CSR) Strategy and Implementation',
      items: [
        'Strategy Design, Program implementation, Participatory Trainings, SDGs & developing strategies in lines with SDG and Post-project assessment.',
      ],
    },
    {
      title: '9. Stakeholder Engagement',
      items: [
        'Evaluating the social impact of operations, including labor practices, community engagement, and human rights issues.',
        'Developing strategies to enhance social well-being and workforce diversity and inclusion.',
      ],
    },
    {
      title: '10. Governance Advisory',
      items: [
        'Advising on best practices in corporate governance, including board diversity, executive pay, and ethical business practices.',
        'Development of governance frameworks to ensure accountability and transparency.',
      ],
    },
    {
      title: '11. Impact Investing and Sustainable Finance Advisory',
      items: [
        'Advising on green bonds, sustainable loans, and other ESG financing options.',
        'Development of strategies for investing in sustainable projects and technologies.',
      ],
    },
    {
      title: '12. Benchmarking and Performance Analysis',
      items: [
        "Comparing a company's ESG performance against industry peers and best practices.",
        'Providing insights and recommendations for improvement.',
      ],
    },
    {
      title: '13. Climate Resilience Advisory and Strategy',
      items: [
        "Assessing an organization's vulnerability to climate change impacts and its readiness to adapt to changing climate conditions.",
        'Developing strategies to mitigate and adapt to the impacts of climate change.',
        'Advising on carbon markets, carbon pricing, and renewable energy opportunities.',
      ],
    },
    {
      title: '14. Circular Economy Metrics and Consulting',
      items: [
        'Advising on strategies to implement circular economy principles, product life cycle extension, reducing waste and promoting recycling and reuse within business operations.',
      ],
    },
    {
      title:
        '15. Consolidated Integration, Implementation and Management of ESG Solutions',
      items: [
        'End to end integration and implementation of mitigative solutions involving the identification of processes and available solutions, procurement and contracting, installation and implementation of solutions, analysis and measurement of improvements achieved and finally reporting.',
      ],
    },
  ]
  return (
    <div className='flex flex-col text-xl font-semibold leading-9 text-left text-black md:mt-12 mt-4 px-4 md:px-12'>
      {esgServices.map((service, index) => (
        <ESGServiceItem
          key={index}
          title={service.title}
          items={service.items}
        />
      ))}{' '}
    </div>
  )
}

export default DeliverablesExpansion
