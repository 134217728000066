import * as React from "react";
function Process() {
  const internshipList = [
    
    "Great work culture with a mix of senior and mid-management.",
    "Freedom and flexibility to navigate work, with focus on results attained.",
    "Industry experts as mentors for great learning experiences.",
    "Career advancement across streams or vertically basis your ambition."
    // "Diverse Industry Exposure: Nirantara serves a wide array of industries, allowing interns to understand the unique sustainability challenges and opportunities across different sectors.",
    // "Career Pathways: The internship program serves as a stepping stone for a career in sustainability and ESG consulting, with high-performing interns often considered for full-time positions at Nirantara as well as placement in other reputed organisations."
  ]
  
  return (
    <section className="px-4 md:px-20 flex-col benefits-section text-black font-semibold max-md:text-4xl">
      {/* {" "}
      <h2 className="w-full uppercase max-md:max-w-full max-md:text-4xl">
        {" "}
        BENEFITS{" "}
      </h2>{" "}
      <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
        {" "}
        Nirantara Internship Program is designed to offer aspiring
        professionals a unique opportunity to immerse themselves in the world of
        Environmental, Social, and Governance (ESG) consulting. This program
        aims to bridge the gap between academic knowledge and real-world
        application, providing hands-on experience in sustainability initiatives
        and ESG integration strategies. Here's a brief overview of what the
        program offers: <br /> Program Highlights  
        <ul className="list-disc px-4 md:px-12">
        {internshipList.map(text=><li>{text}</li>)}
      </ul>
      </p>{" "} */}
      <h2 className="mt-16 lg:text-5xl text-left uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        Accelerate your growth
      </h2>
      <p className="mt-6 w-full text-left text-lg leading-9 font-light text-black max-md:max-w-full">At the heart of Nirantara is a collaborative culture that values open communication, teamwork, and continuous learning. A friendly work environment with an ‘open door’ policy, ability to work directly with Senior Management to shape the company and an opportunity for professional development and growth.</p>
      <p className="mt-6 w-full text-left text-lg leading-9 font-light text-black max-md:max-w-full">Join us and create a legacy that truly makes a difference. We’re dedicated to your growth and committed to helping you become the best professional you can be. Together, let’s achieve greatness and make an impact that we can all be proud of. Your journey with us will be one of support, growth, and shared success.</p>
      
      {/* <ul className=" mt-6 w-full text-left text-lg leading-9 font-light text-black max-md:max-w-full list-disc px-8">
        {internshipList.map(text=><li>{text}</li>)}
      </ul> */}
      {/* <p className="mt-6 w-full text-left text-lg leading-9 font-light text-black max-md:max-w-full">
        Great work culture with a mix of senior and mid-management.
      </p>
      <p className="mt-6 w-full text-left text-lg leading-9 font-light text-black max-md:max-w-full">
        Freedom and flexibility to navigate work, with focus on results attained.
      </p>
      <p className="mt-6 w-full text-left text-lg leading-9 font-light text-black max-md:max-w-full">
        Industry experts as mentors for great learning experiences.
      </p>
      <p className="mt-6 w-full text-left text-lg leading-9 font-light text-black max-md:max-w-full">
        Career advancement across streams or vertically basis your ambition.
      </p> */}
      {/* <h2 className="mt-16 w-full uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        {" "}
        IDEAL CANDIDATES{" "}
      </h2>{" "}
      <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
        {" "}
        We seek motivated who are passionate about sustainability and have a
        keen interest in ESG. Candidates should be currently enrolled in or
        recent graduates of relevant programs in environmental studies,
        sustainability, business, or related fields. Critical thinking,
        creativity, and a collaborative spirit are key attributes we look for.{" "}
      </p>{" "}
      <h2 className="mt-16 w-full uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        {" "}
        APPLICATION PROCESS{" "}
      </h2>{" "}
      <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
        {" "}
        The application process involves submitting a resume, a cover letter
        detailing your interest in sustainability and ESG, and any relevant
        coursework or projects. Selected candidates will be invited for an
        interview to discuss their interests and potential fit with Nirantara
        mission and values.{" "}
      </p>{" "} */}
      {/* <h2 className="mt-16 w-full uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        {" "}
        SELECTION PROCESS{" "}
      </h2>{" "}
      <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
        {" "}
        Written examination <br /> Online Interview (first round) <br />{" "}
        In-Person Interview (second round) <br /> <br /> Making an Impact <br />{" "}
        The Nirantara Internship Program is more than just an internship; it's
        an opportunity to make a real impact. Interns leave the program not just
        with enhanced skills and knowledge, but with the satisfaction of having
        contributed to the global sustainability agenda. Join us to embark on a
        rewarding journey towards building a sustainable future. <br />{" "}
      </p>{" "} */}
    </section>
    
  );
}
export default Process;
