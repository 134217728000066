import * as React from "react";

function SustainabilityCard({
  imageSrc,
  iconSrc,
  title,
  description,
  bgColor,
}) {
  return window.innerWidth > 768 ? (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className={`flex overflow-hidden relative flex-col grow justify-center ${window.innerWidth > 1100 ? 'min-h-[429px]': 'min-h-[295px]'} _journey_images max-md:max-w-full`}>
        <img
          src={imageSrc}
          alt=""
          className="object-cover    inset-0  lg:size-full"
        />
        {/* <div
          className={`flex relative flex-col px-20 py-9 ${bgColor} max-md:px-5 max-md:max-w-full`}
        >
          <img src={iconSrc} alt="" className="aspect-square w-[50px]" />
          <h3 className="mt-8 text-2xl font-extrabold leading-10 text-white uppercase">
            {title}
          </h3>
          <p className="mt-3.5 text-xs font-medium leading-5 text-white">
            {description}
          </p>
        </div> */}
      </div>
    </div>
  ) : window.innerWidth > 890 ?
    (<div className="flex flex-col w-full max-md:w-full">
      <div className={`relative ${bgColor}`}>
        {/* Image */}
        <img
          src={imageSrc}
          alt=""
          className="object-cover w-full h-full aspect-w-3 aspect-h-2 max-h-[300px] max-md:max-h-full"
        />
        {/* Overlay for mobile */}
        <div className="absolute inset-0 flex items-center justify-center max-md:hidden">
          <img src={iconSrc} alt="" className="w-12 h-12" />
        </div>
        {/* Content */}
        <div className="hidden max-md:flex flex-col justify-center items-center absolute inset-0 px-4 py-6">
          <img src={iconSrc} alt="" className="aspect-square w-12 mb-4 p-4" />
          {/* <h3 className="text-xl font-extrabold leading-8 text-white text-center uppercase">
            {title}
          </h3>
          <p className="mt-3 text-sm font-medium leading-5 text-white text-center">
            {description}
          </p> */}
        </div>
      </div>
    </div>  
    )
    : (
      <div className="flex flex-col w-full max-md:w-full">
        <div className={`flex overflow-hidden relative flex-col grow justify-center ${bgColor}`}>
          <div className="px-4 py-6 max-md:px-2">
            <img src={iconSrc} alt="" className="aspect-square w-12 mb-4 mx-auto" />
            <h3 className="text-xl font-extrabold leading-8 text-white text-center uppercase">
              {title}
            </h3>
            <p className="mt-3 text-sm font-medium leading-5 text-white text-center">
              {description}
            </p>
          </div>
        </div>
      </div>
    );
}

function Sustainbility() {
  const sustainabilityData = [
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/8b719f376c1d12e9bd379ad4625fcb301e4be159d28fd01b89c720b44a5aeb5b?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      iconSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/205907eb000bec6583b5a96f1c2dc8f40f6b48863c2a0695f23a7659e27c2365?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      title: "SUSTAINABILITY AS A CORE VALUE",
      description:
        "Nirantara places sustainability at the heart of everything it does, striving to create solutions that balance economic success with environmental stewardship and social well-being. The company believes in acting as a catalyst for change, helping clients integrate Environmental, Social, and Governance (ESG) principles into their strategies to achieve long-term, sustainable growth. By prioritizing sustainability, Nirantara commits to contributing positively to the planet and society.",
      bgColor: "bg-lime-600",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/a7a92d9c4968404204639f2756b30dccc0e0073a30b168663f066d5f3bea0eaa?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      iconSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/1fbf9cb92a6d33cf21c8b81d61189b71b53016b937b0ca7e1db25dac1aa339d5?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      title: "Integrity and transparency",
      description:
        "Trust is paramount in all of Nirantara relationships, from clients and partners to employees and the communities it serves. The company upholds the highest standards of integrity and ethics, conducting its business with honesty and transparency. Nirantara believes in open communication, ensuring that its actions and decisions are clear and understandable to all stakeholders, thereby fostering trust and credibility.",
      bgColor: "bg-yellow-900",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/0fdadc104f82e148f4cb264c2b2297931fd763fa805d3313f2232e1a8cbb982e?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      iconSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/804a0a224b39e2263818d68d701682cd2be39a2723ef007ad6b4d2dfc98fe280?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      title: "Innovation and adaptability",
      description:
        "In the fast-evolving landscape of sustainability and corporate responsibility, Nirantara embraces innovation and adaptability as key drivers of success. The company invests in research and development, stays abreast of the latest trends and technologies, and encourages creative thinking among its team. This commitment to innovation enables Nirantara to offer cutting-edge solutions to its clients, helping them navigate the complexities of sustainability in an ever-changing world.",
      bgColor: "bg-lime-600",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/95cb222f44ce46fbfb168e0e3f367f06ba6276c02ed89669f65e27dba253bfdf?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      iconSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/2803c3514fb4334943d2297e3ecb98c8ea7d52844a199d9a4e992b92a3efe72e?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      title: "Collaboration and partnership",
      description:
        "Nirantara recognizes that addressing global sustainability challenges requires collective action. As such, the company fosters a culture of collaboration, working closely with clients, industry peers, non-governmental organizations, and communities to achieve common goals. By leveraging the power of partnership, Nirantara amplifies its impact, driving forward towards a more sustainable future for everyone.",
      bgColor: "bg-yellow-900",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/c1056e3683226f9d57b7dba5029543a9758929de8f443fa4e31e043a867bfb06?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      iconSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/3e21808287f6b8b83d8df1acabb2c1bd04cfd79e9306000a46bc37fda0c5683a?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      title: "Commitment to excellence",
      description:
        "At Nirantara, excellence is not just a goal but a continuous journey. The company is dedicated to delivering the highest quality of service, constantly seeking ways to improve and exceed expectations. Through a rigorous approach to project management, a dedication to client success, and a relentless pursuit of excellence, Nirantara ensures that it remains a leader in the field of sustainability consultancy.",
      bgColor: "bg-lime-600",
    },
    {
      imageSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/fbf47bc98060cbb1eaeeabc6de323388a29b0681423d6a8bffb8014174fe7670?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      iconSrc:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/af90cbb95cf162f82adb8b339aa9c3278023fc29e3d1475873f9c08bbb16e96c?apiKey=b3217365a35d45c196e47ed20ac8b87c&",
      title: "Empowerment and development",
      description:
        "Nirantara believes in empowering its employees and the communities it serves. The company is committed to fostering an inclusive, diverse work environment where talent thrives, and ideas flourish. By investing in the development of its team and supporting community initiatives, Nirantara not only contributes to individual growth but also strengthens the fabric of society.",
      bgColor: "bg-yellow-900",
    },
  ];

  return (
    <main className="flex flex-col gap-24 items-center mb-8 md:mb-24 ">
      <div className="flex flex-col">
        <section className=" w-full max-md:max-w-full">
          <div className="flex  max-md:flex-col max-md:gap-0">
            {sustainabilityData.slice(0, 3).map((data, index) => (
              <SustainabilityCard key={index} {...data} />
            ))}
          </div>
        </section>
        <section className=" w-full max-md:max-w-full">
          <div className="flex  max-md:flex-col max-md:gap-0">
            {sustainabilityData.slice(3).map((data, index) => (
              <SustainabilityCard key={index} {...data} />
            ))}
          </div>
        </section>
      </div>



      <p style={{fontWeight:'400'}} className="text-black helvetica  text-xl leading-7  px-4">
        The ethos of Nirantara reflects a deep-rooted commitment to creating a
        sustainable, equitable, and prosperous future. By adhering to these core
        principles, Nirantara stands committed to being a leader in driving
        meaningful change.
      </p>
    </main>
  );
}

export default Sustainbility;
