import * as React from "react";

function Text() {
  return (
    <article className="text-lg leading-9 text-black max-w-7xl px-2 flex flex-col gap-4 md:gap-8 ">
      <p>
        Discover how we can integrate environmental responsibility, social equity, and economic prosperity into every aspect of your operations. At Nirantara, we are committed to helping your organization to build a better future for our planet and its inhabitants. Sustainability isn't just a buzzword for us; it's a core value that guides everything we do.
      </p>
      <p>
        To us, Sustainability means being mindful of our environmental impact, treating our employees and communities with respect, and creating products and services that contribute positively to society. All this while focusing on a meaningful improvement in the organization's profitablity, consistently.
      </p>
      <p>
        Through simple but decisive steps towards reducing waste, conserving resources, and minimizing carbon footprint, to manufacturing processes, to the materials used, we strive to help you make responsible choices every step of the way.
      </p>
      <p>
        In our view, Sustainability is more than focusing on just environmental stewardship. It's also about social responsibility. We are dedicated to partner with you to foster an inclusive and diverse workplace where everyone has the opportunity to substantially contribute to the growth of your business as your stakeholders. We will walk you through viable initiatives to cater to your local communities.
      </p>
      <p>
        Ultimately, sustainability is about thinking long-term and acting responsibly today to ensure a brighter tomorrow and for generations to come. Allow us to join you on your journey towards a more sustainable future, one decision at a time. Together, we can make a difference.
      </p>
    </article>
  );
}

export default Text;