import * as React from "react";

function Landing() {
  const carrerURL =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/46bdcfc66628a443be0b2a35071395eb131822eaed711155c49acbd8b82126d0?apiKey=b3217365a35d45c196e47ed20ac8b87c&";

  return (
    <div className="flex flex-col justify-center text-white w-full">
      <div
        className={`flex overflow-hidden relative flex-col items-center px-16 pt-6 pb-14 w-full banner_container lg:min-h-[calc(100vh-100px)] max-md:px-5 max-md:max-w-full`}
      >
        <img
          loading="lazy"
          src={
            window.location.pathname.includes("career")
              ? carrerURL
              : process.env.PUBLIC_URL + "/assets/images/certification.png"
          }
          alt="Background"
          className="object-cover absolute inset-0 size-full"
        />
      </div>

      {/* Introduction Section */}
      <div className=" px-4  md:px-20 flex flex-col text-5xl font-semibold text-black max-md:text-4xl">
        <h1 className=" mt-16 text-black text-left lg:text-5xl font-semibold uppercase helvetica " >
        BE THE FORCE OF CHANGE THAT MAKES AN IMPACT{" "}
      </h1>
       
        <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
          At Nirantara, work is more than just a task; it’s a journey of growth and impact.
          Our strong work ethic and robust processes provide a deep sense of satisfaction,
          knowing we contribute to the global sustainability agenda.
        </p>
        <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
          We are building a robust resource base to support enterprises across India’s
          industrial belts, fostering impactful change while advancing careers in the
          fast-evolving field of ESG and sustainability.
        </p>
        <p className="mt-6 w-full text-lg leading-9 font-light text-black max-md:max-w-full">
          Join us to embark on a rewarding journey towards building a sustainable future.
        </p>
      </div>

     
    </div>
  );
}

export default Landing;
