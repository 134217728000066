import * as React from "react";

const LinkItem = ({ href, text }) => (
  <a href={href} className="text-black underline" target="_blank">
    {text}
  </a>
);

const links = [
  {
    category: "Global Reporting Initiative",
    items: [
      {
        href: "https://www.globalreporting.org/",
        text: "GRI - Home (globalreporting.org)",
      },
      {
        href: "https://www.globalreporting.org/standards/download-the-standards/",
        text: "GRI - Download the Standards (globalreporting.org)",
      },
    ],
  },
  {
    category: "Business Responsibility & Sustainability Reporting",
    items: [
      {
        href: "https://www.sebi.gov.in/sebi_data/commondocs/jul-2023/Annexure_I-Format-of-BRSR-Core_p.pdf",
        text: "https://www.sebi.gov.in/sebi_data/commondocs/jul-2023/Annexure_I-Format-of-BRSR-Core_p.pdf",
      },
      {
        href: "https://www.sebi.gov.in/sebi_data/commondocs/jul-2023/Annexure_II-Updated-BRSR_p.PDF",
        text: "https://www.sebi.gov.in/sebi_data/commondocs/jul-2023/Annexure_II-Updated-BRSR_p.PDF",
      },
    ],
  },
  {
    category: "CDP",
    items: [
      { href: "https://www.cdp.net/en/", text: "Home - CDP" },
      {
        href: "https://www.cdp.net/en/guidance/guidance-for-companies",
        text: "Guidance for companies - CDP",
      },
    ],
  },
  {
    category: "Supplier Assurance",
    items: [
      { href: "https://supplierassurance.com/", text: "SUPPLIERASSURANCE" },
      {
        href: "https://supplierassurance.com/files/downloads/questionnaires/en_us/automotive_saq.pdf",
        text: "Questionnaire (supplierassurance.com)",
      },
    ],
  },
  {
    category: "GHG Protocol",
    items: [
      { href: "https://ghgprotocol.org/", text: "Homepage | GHG Protocol" },
      {
        href: "https://ghgprotocol.org/corporate-standard",
        text: "Corporate Standard | GHG Protocol",
      },
      {
        href: "https://ghgprotocol.org/scope-2-guidance",
        text: "Scope 2 Guidance | GHG Protocol",
      },
      {
        href: "https://ghgprotocol.org/scope-3-calculation-guidance-2",
        text: "Scope 3 Calculation Guidance | GHG Protocol",
      },
    ],
  },
  {
    category: "SimaPro",
    items: [
      {
        href: "https://simapro.com/",
        text: "SimaPro | LCA software for informed changemakers",
      },
      {
        href: "https://pre-sustainability.com/articles/life-cycle-assessment-lca-basics/",
        text: "Life Cycle Assessment (LCA) explained - PRé Sustainability (pre-sustainability.com)",
      },
    ],
  },
  {
    category: "The Intergovernmental Panel on Climate Change (IPCC)",
    items: [
      {
        href: "https://www.ipcc.ch/",
        text: "IPCC — Intergovernmental Panel on Climate Change",
      },
      {
        href: "https://www.ipcc.ch/report/sixth-assessment-report-cycle/",
        text: "AR6 Synthesis Report: Climate Change 2023 — IPCC",
      },
      {
        href: "https://www.ipcc.ch/report/ar5/syr/",
        text: "AR5 Synthesis Report: Climate Change 2014 — IPCC",
      },
    ],
  },
  {
    category: "UNFCCC",
    items: [
      { href: "https://unfccc.int/", text: "UNFCCC" },
      {
        href: "https://unfccc.int/cop28",
        text: "UN Climate Change Conference - United Arab Emirates | UNFCCC",
      },
    ],
  },
  {
    category: "UN SDGs",
    items: [
      {
        href: "https://sdgs.un.org/",
        text: "Home | Sustainable Development (un.org)",
      },
      {
        href: "https://sdgs.un.org/2030agenda",
        text: "Transforming our world: the 2030 Agenda for Sustainable Development | Department of Economic and Social Affairs (un.org)",
      },
      {
        href: "https://sdgs.un.org/goals",
        text: "THE 17 GOALS | Sustainable Development (un.org)",
      },
    ],
  },
  {
    category: "United Nations Global Compact (UNGC)",
    items: [
      {
        href: "https://unglobalcompact.org/",
        text: "Homepage | UN Global Compact",
      },
      {
        href: "https://unglobalcompact.org/what-is-gc/mission/principles",
        text: "The Ten Principles | UN Global Compact",
      },
      {
        href: "https://unglobalcompact.org/library/231",
        text: "UN Global Compact Management Model | UN Global Compact",
      },
      {
        href: "https://unglobalcompact.org/library/1151",
        text: "Guide to Corporate Sustainability | UN Global Compact",
      },
    ],
  },
  {
    category: "WWF",
    items: [
      {
        href: "https://www.worldwildlife.org/",
        text: "WWF - Endangered Species Conservation | World Wildlife Fund",
      },
      {
        href: "https://www.worldwildlife.org/business",
        text: "Changing the Nature of Business | World Wildlife Fund",
      },
      { href: "https://www.worldwildlife.org/business", text: "C" },
      {
        href: "https://www.worldwildlife.org/business",
        text: "hanging the Nature of Business | World Wildlife Fund",
      },
      {
        href: "https://www.worldwildlife.org/initiatives/infrastructure",
        text: "Infrastructure | Initiatives | WWF (worldwildlife.org)",
      },
    ],
  },
];

function Text() {
  return (
    <div class="mt-6 ml-4 text-lg leading-9 text-black max-md:max-w-full">
      <h2 class="mb-4 font-bold underline">Links (external) for resources</h2>
      {links.map(({ category, items }) => (
        <section key={category}>
          <h3 class="lg:mr-8 font-semibold">{category}</h3>
          <ul class="list-decimal pl-6 ml-8">
            {items.map(({ href, text }, index) => (
              <li key={`${category}-${index}`} class="pl-2">
                {/* <LinkItem href={href} text={text} /> */}
                <a href={href} className="text-black underline break-words" target="_blank">
                  {text}
                </a>
              </li>
            ))}
          </ul>
        </section>
      ))}
    </div>

  );
}

export default Text;
