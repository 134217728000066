import * as React from "react";


function Landing() {
  return (
    <div className="flex flex-col justify-center text-white w-full ">
      <div className="flex overflow-hidden relative flex-col items-center px-16 pt-6 pb-14 w-full min-h-[300px] md:min-h-[calc(100vh-400px)] lg:min-h-[calc(100vh-100px)]  max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src={process.env.PUBLIC_URL + `/assets/images/memberships.png`}
          alt="Background"
          className="absolute inset-0 size-full object-bottom"
        />
      </div>
    </div>
  );
}

export default Landing;
