import * as React from "react";

function Landing() {
  return (
    <div className="flex flex-col justify-center text-white w-full ">
      <div className="flex overflow-hidden relative flex-col items-center px-16 pt-6 pb-14 w-full banner_container min-h-[calc(100vh-100px)] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b640033098167a3e37dd0fb2093dd0cea8319c301fc711e21c63b7413c072062?apiKey=b3217365a35d45c196e47ed20ac8b87c&"
          alt="Background"
          className="object-cover absolute inset-0 size-full"
        />
       
      </div>
    </div>
  );
}

export default Landing;
