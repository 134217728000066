import * as React from "react";

function Landing() {
  return (
    <section className="flex flex-col text-center max-w-6xl ">
      <h1 className="w-full text-7xl font-black text-black uppercase max-md:max-w-full max-md:text-4xl">
        NIRANTARA PRODUCTS
      </h1>
      <p className="self-center mt-9 text-lg leading-9 text-black max-md:max-w-full">
        Nirantara is the Sanskrit word which means "Continuous". Nirantara
        journey towards sustainability embodies the spirit of continuity in
        innovation, responsibility, and a deep commitment to create a
        "Continuous" balance.
      </p>
    </section>
  );
}

export default Landing;