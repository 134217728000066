export default function scrollToSentence(sentence) {
    const words = sentence.split(' ');
    const walker = document.createTreeWalker(document.body, NodeFilter.SHOW_TEXT, null, false);

    let found = false;
    while (!found && walker.nextNode()) {
        const node = walker.currentNode;

        if (node.nodeValue && node.nodeValue.includes(sentence)) {
            found = true;
            const range = document.createRange();
            range.setStart(node, node.nodeValue.indexOf(sentence));
            range.setEnd(node, node.nodeValue.indexOf(sentence) + sentence.length);
            const rect = range.getBoundingClientRect();
            setTimeout(() => {
                window.scrollTo({
                  top: rect.top + window.scrollY - 50, // -50 to make it center 
                  behavior: 'smooth'
                });
              }, 1000); 
              
        }
    }
}