const searchJson = [
  {
    route: '/',
    sentences: [
      "Welcome to our homepage.",
      "Explore our latest offers and updates here.",
      "This is the main landing page of our website."
    ],
    page: 'Home',
    keywords: ['home']
  },
  {
    route: '/products',
    page: 'Products',
    sentences: [
      "Browse through our wide range of products.",
      "Discover the latest products and innovations here.",
      "Nirantara is the Sanskrit word which means 'Continuous'.",
      "ESG TECH PLATFORM",
      "Nirantara journey towards sustainability embodies the spirit of continuity in innovation, responsibility, and a deep commitment to create a 'Continuous' balance.",
      "In a world increasingly driven by digital innovation, Nirantara is set to redefine the landscape of corporate sustainability with its upcoming ESG Tech Platform, designed to encompass a holistic approach to managing and mitigating Scopes 1, 2, and 3 greenhouse gas (GHG) emissions. This innovative platform is crafted to empower businesses to not only navigate but also excel in their sustainability journey, ensuring they can effectively measure, analyze, and reduce their environmental footprint across all emission scopes.",
      "PLATFORM OVERVIEW",
      "Nirantara ESG Tech Platform is a comprehensive, cloud-based solution that enables businesses to measure, manage, and report their sustainability performance. By leveraging cutting-edge technology, including AI-driven analytics, blockchain for transparency, and IoT for real-time monitoring, the platform offers an unmatched toolkit for companies committed to making a genuine impact.",
      "Direct Emissions Management For Scope 1 emissions, which come directly from sources that are controlled by the company (such as emissions from combustion in owned or controlled boilers, furnaces, vehicles), the platform offers advanced monitoring tools. These tools enable real-time tracking of direct emissions, helping businesses identify high-emission areas and implement targeted reduction strategies. Integration with IoT devices provides precise data collection, facilitating informed decision-making and operational adjustments.",
      "Indirect Emissions from Purchased Electricity Addressing Scope 2 emissions, which are indirect emissions from the generation of purchased electricity consumed by the company, Nirantara platform includes features for tracking energy consumption patterns. It provides analytics for assessing the carbon footprint of electricity use and supports the transition to green energy sources. The platform aids companies in negotiating power purchase agreements (PPAs) for renewable energy and offers insights into leveraging renewable energy certificates (RECs) to offset Scope 2 emissions.",
      "Value Chain Emissions Insight Perhaps the most challenging to manage, Scope 3 emissions include all other indirect emissions that occur in a company's value chain, from upstream activities like raw material extraction to downstream processes such as product use and end-of-life disposal. Nirantara platform offers comprehensive supply chain analysis tools, enabling businesses to map out and evaluate the carbon footprint of their entire value chain. By fostering collaboration with suppliers and customers through the platform, companies can encourage sustainable practices throughout their supply chain, driving industry-wide changes.",
      "Cross-Scope Features for Comprehensive Sustainability Management Data-Driven Insights: The platform uses AI and machine learning to provide actionable insights, helping businesses prioritize reduction efforts where they can have the greatest impact. Reporting and Compliance: With built-in reporting tools, the platform simplifies compliance with global sustainability standards and regulations, making it easier for companies to communicate their ESG achievements. Stakeholder Engagement: Through its stakeholder engagement module, the platform enhances transparency and fosters dialogue around sustainability efforts, building trust and alignment with investors, customers, and communities. Nirantara upcoming ESG Tech Platform is more than just a software solution; it represents a commitment to pioneering a sustainable future. By addressing Scopes 1, 2, and 3 emissions comprehensively, Nirantara ensures businesses can take a proactive and informed approach to their environmental impact. This platform is poised to become an indispensable tool for companies looking to lead in sustainability, demonstrating that effective environmental stewardship is within reach for businesses of all sizes and sectors.",
      "Assessment and Materiality Analysis  Begin with a thorough assessment of current practices and a materiality analysis to identify key ESG areas that align with business goals and stakeholder interests. Crafting comprehensive ESG strategies aligned with business goals. Integration of ESG principles into corporate strategy and operations. Identifying and quantifying ESG - related risks, including regulatory, reputational, and financial risks, to inform risk management strategies.",
      "Assistance in creating sustainability reports following standards like BRSR, GRI, SASB, ECOVADIS, CDP and TCFD.",
      "Development of carbon footprint reports and other environmental impact assessments",
      "Conducting due diligence for mergers, acquisitions, and investments from an ESG perspective (ESMS / ESAPS / SAQ).",
      "Identifying ESG risks and opportunities in potential investments. ",
      "Guidance on compliance with local and international ESG regulations.  Identifying and managing ESG-related risks.",
      "Carbon footprinting. Calculation of greenhouse gas (GHG) emissions across different scopes (Scope 1, 2, and 3) to identify major sources of emissions and opportunities for reduction   ",
      "Product Life Cycle Assessment (LCA) calculation",
      "Water Footprint Assessment - Quantifying water usage and efficiency within an organization's operations and supply chain to identify conservation opportunities",
      "Waste Management Analysis - Measuring waste generation and diversion rates to improve waste management practices, including reduction, recycling, and reuse strategies.",
      "Biodiversity impact assessments. - Quantifying the impact of operations on local and global biodiversity to develop strategies for conservation and positive contributions to ecosystems.",
      "Energy Efficiency Analysis - Assessing energy consumption patterns to identify efficiency improvements and opportunities for transitioning to renewable energy sources.",
      "Measuring the sustainability performance of suppliers and the supply chain to identify risks and opportunities for improvement",
      "Evaluating the social impact of operations, including labor practices, community engagement, and human rights issues.",
      "Developing strategies to enhance social well-being and workforce diversity and inclusion (DEI).   ",
      "Capacity Building, Qualitative Monitoring and Evaluation",
      "Social, Health and Safety Programs",
      "Strategy Design, Program implementation, Participatory Trainings, SDGs & developing strategies in lines with SDG and Post-project assessment",
      "Evaluating the social impact of operations, including labor practices, community engagement, and human rights issues.",
      "Developing strategies to enhance social well-being and workforce diversity and inclusion.    ",
      "Advising on best practices in corporate governance, including board diversity, executive pay, and ethical business practices.",
      "Development of governance frameworks to ensure accountability and transparency.      ",
      "Advising on green bonds, sustainable loans, and other ESG financing options.  ",
      "Development of strategies for investing in sustainable projects and technologies.       ",
      "Comparing a company's ESG performance against industry peers and best practices.  Providing insights and recommendations for improvement.",
      "Assessing an organization's vulnerability to climate change impacts and its readiness to adapt to changing climate conditions. Developing strategies to mitigate and adapt to the impacts of climate change.     Advising on carbon markets, carbon pricing, and renewable energy opportunities.",
      "Advising on strategies to implement circular economy principles, product life cycle extension, reducing waste and promoting recycling and reuse within business operations.",
      "End to end integration and implementation of mitigative solutions involving the identification of processes and available solutions, procurement and contracting, installation and implementation of solutions, analysis and measurement of improvements achieved and finally reporting.",
      "Employee Engagement: Customized workshops and training sessions for specific topics as well as to embed a culture of sustainability and social responsibility across all levels of your organization.",
      "Leadership Advisory: Strategic advice to leadership teams on integrating ESG considerations into corporate governance and-making processes",
      "Vendor and contractor alignment workshops: Offering workshops, seminars, and training sessions on ESG topics to vendors, suppliers and contractors.",
      "Boosting ESG Monitoring and Reporting Abilities: Building organisational capacity to measure, manage and report on ESG issues.",
      "Collaborations with Academic institutions for  Practical integrating ESG topics across disciplines, not just in environmental studies or business courses but also in engineering, law, and social sciences, to provide a holistic understanding of sustainability challenges.   Research and Innovation: Exploring topics like climate change mitigation, social inequality, corporate governance, and sustainable finance.  Social Projects: Bring academic expertise to solve real-world problems and fostering innovation in sustainability practices",
      "Institutional Governance and Operations  Sustainable Campus Initiatives: Collaborations with Academic Institutions for implementing ESG principles in their own operations, from reducing carbon footprints and enhancing energy efficiency to promoting diversity and inclusion within their communities.",
      "Public Engagement  Academia plays a crucial role in raising public awareness about ESG issues, hosting lectures, seminars, and conferences that are open to the broader community.",
      "Student-Led Initiatives Collaborating ESG initiatives on campuses promoting student leadership, reflecting the increasing demand for sustainability in education and action among the younger generation.",
      "Industry readiness pathways Integrating practical ESG related activities into education for enhanced skill development creating industry ready professionals focused on sustainability roles."
    ],
    keywords: ['products', 'Nirantara', 'Continuous', 'journey', 'sustainability', 'innovation', 'responsibility', 'commitment', 'balance']
  },
  {
    route: '/journey',
    page: 'Journey',
    sentences: [
      "SUSTAINING TOMORROW, ONE STEP AT A TIME: NIRANTARA JOURNEY",
      "Nirantara journey towards sustainability embodies the spirit of continuity in innovation, responsibility, and a deep commitment to create a Continuous balance between profitablity and the planet. Founded with the aim to integrate sustainability into the core of business practices, Nirantara has become a beacon of progress in the realm of environmental, social, and governance (ESG) principles. This brief write-up captures the essence of Nirantara sustainable journey.",
      "NIRANTARA VISION Nirantara vision is to be the preferred partner for sustainable change, by offering a multitude of services and integrated solutions in a single window, to enable businesses and communities to prosper collaboratively.",
      "NIRANTARA MISSION  Nirantara mission is to demystify sustainability by making it accessible and feasible for enterprises across all business streams irrespective of the size. The Nirantara team will strive to promote awareness of sustainability frameworks while providing cost-effective solutions and services for enterprises to easily adopt sustainable practices in their operations. Nirantara aims to be a one-stop-shop solution provider for businesses across different spectrums who aim to achieve Net-Zero status and optimized social balance within the organization.",
      "NIRANTARA ETHOS  Nirantara is founded on a set of core principles and values that guide its vision, mission, operations, and interactions with clients, communities, and the environment. The ethos of Nirantara outlines the commitment to sustainability, integrity, innovation, collaboration, and excellence. The principle pillars of Nirantara ethos are as follows",
      "The ethos of Nirantara reflects a deep-rooted commitment to creating a sustainable, equitable, and prosperous future. By adhering to these core principles, Nirantara stands committed to being a leader in driving meaningful change."
    ],
    keywords: ['Journey']
  },
  {
    route: '/career',
    page: 'career',
    sentences: [
      "JOB OPENINGS ",
      "ESG Manager",
      "Junior ESG Executive ",
      "Marketing Executive   ",
      "Marketing Executive",
      "INTERNSHIP",
      "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers:   Program Highlights",
      "Comprehensive Exposure:Interns at Nirantara gain exposure to a wide range of sustainabilityprojects, from developing ESG reporting frameworks for clients to participating in sustainability assessments and implementing green initiatives.",
      "Mentorship and Learning: Each intern is paired with experienced mentors from our team of ESG consultants, offering personalized guidance and insights into the consulting profession and the burgeoning field of sustainability.",
      "Professional Development: The program is designed to equip interns with critical skills needed in the ESG space, including data analysis, stakeholder engagement, sustainability reporting, and strategic thinking.",
      "Collaborative Projects: Interns work collaboratively on projects, fostering teamwork and communication skills while contributing to meaningful sustainability outcomes for our clients.      ",
      "Diverse Industry Exposure: Nirantara serves a wide array of industries, allowing interns to understand the unique sustainability challenges and opportunities across different sectors.",
      "Career Pathways: The internship program serves as a stepping stone for a career in sustainability and ESG consulting, with high-performing interns often considered for full-time positions at Nirantara as well as placement in other reputed organisations.",
      "IDEAL CANDIDATES",
      "We seek motivated who are passionate about sustainability and have a keen interest in ESG. Candidates should be currently enrolled in or recent graduates of relevant programs in environmental studies, sustainability, business, or related fields. Critical thinking, creativity, and a collaborative spirit are key attributes we look for.",
      "APPLICATION PROCESS",
      "The application process involves submitting a resume, a cover letter detailing your interest in sustainability and ESG, and any relevant coursework or projects. Selected candidates will be invited for an interview to discuss their interests and potential fit with Nirantara mission and values.",
      "SELECTION PROCESS",
      "Written examination",
      "Online Interview (first round)",
      "In-Person Interview (second round) ",
      "Making an Impact ",
      "The Nirantara Internship Program is more than just an internship; it's an opportunity to make a real impact. Interns leave the program not just with enhanced skills and knowledge, but with the satisfaction of having contributed to the global sustainability agenda. Join us to embark on a rewarding journey towards building a sustainable future."

    ],
    keywords: ['Career']
  },
  {
    route: '/our-team',
    page: 'Our Team',
    sentences: [
      "Nirantara team ",
      "CHAMPIONS OF SUSTAINABLE PROGRESS",
      "At Nirantara, our team is our greatest asset and the driving force behind our mission to integrate Environmental, Social, and Governance (ESG) principles into the core of business operations worldwide. Composed of a diverse group of seasoned professionals, innovators, and thought leaders with expertise diverse industries including buit not limited to sustainability, finance, trading, manufacturing, logistics, shipping, consultancy, corporate strategy, and environmental science, the Nirantara team is united by a common goal: to create a more sustainable and equitable future for all",
      "CORE STRENGTHS ",
      "Multidisciplinary Expertise: Our team brings together specialists from various fields, including environmental management, social science, corporate governance, data analytics, and sustainable finance. This multidisciplinary approach enables us to offer comprehensive ESG solutions tailored to the unique needs of each client.",
      "Qualified and certified professionals: From the leadership downwards, the entire team are Certified for ISO 9001:2015 (QMS), ISO 14001:2015 (EMS), ISO 45001:2018 (OH&S), ISO 50001:2018 (ENMS), ISO 22000:2018 (FSMS), ISO/IEC 27001:2022 (ISMS); ISO/IEC 20000-1:2018 (ITSM), ISO 22301:2019 (BCMS), ISO 29001:2020 (Petroleum, Petrochemical and Natural Gas Industries), ISO 37001:2016 (Anti-bribery), ISO 28000:2022 (Security Management), ISO 15378:2017 (Packaging QMS), ISO 21001:2018 (EOMS) and SA 8000 (Social Accountability).",
      "Passion for Sustainability: Each member of our team is deeply committed to sustainability, not just as a profession but as a personal ethos. This passion drives our relentless pursuit of innovative solutions that can make a real difference in the world.",
      "Collaborative Spirit: We believe in the power of collaboration, both within our team and with our clients, partners, and the broader community. Our collaborative culture fosters open communication, mutual respect, and a shared vision for achieving sustainable outcomes.",
      "Experienced Consultants: The consultants at Nirantara are seasoned professionals with extensive experience in ESG consulting, corporate strategy, and sustainability reporting. They have guided numerous businesses through the complexities of ESG integration, leveraging best practices and innovative strategies to achieve sustainable success.",
      "Innovative Technologists: Our technology team specializes in developing and implementing cutting-edge solutions for ESG data management, analysis, and reporting. By harnessing the power of AI, blockchain, and IoT, they enable our clients to monitor their sustainability performance with precision and ease.",
      "Engaged Project Managers: Nirantara project managers excel in orchestrating complex projects, ensuring seamless coordination between our team and our clients. Their meticulous attention to detail and commitment to timelines guarantee that every project is delivered with excellence and achieves its intended impact.",
      "Collaborative Culture: At the heart of Nirantara is a collaborative culture that values open communication, teamwork, and continuous learning. We believe that by working together, sharing knowledge, and fostering innovation, we can overcome sustainability challenges and make a lasting impact on the world.",
      "The Nirantara team is not just a group of individuals , it's a dynamic community of professionals committed to making sustainability achievable for every business. Together, we are shaping a more sustainable, equitable, and prosperous future for all.",
      "OUR LEADERSHIP",
      "Our leadership team comprises seasoned professionals with extensive experience in sustainability consulting and a proven track record of guiding companies through their ESG journeys. They are not only strategic thinkers but also committed visionaries with hands-on leadership experience and who are actively involved in project work, ensuring that our high standards of quality and impact are consistently met.",
      "OUR COMMITMENT TO CONTINUOUS LEARNING     ",
      "In the fast-evolving field of sustainability, continuous learning is essential. We invest in ongoing professional development for our team, keeping abreast of the latest trends, regulations, and best practices in ESG and sustainability. This commitment to learning ensures that we remain at the forefront of the industry, equipped to offer our clients the most current and effective strategies.",
      "The Nirantara team is more than just a group of professionals; we are a community of sustainability visionaries dedicated to driving change. Together, we are working towards a common mission of a sustainable business landscape that not only thrives economically but also contributes positively to society and the environment.",
      "JOIN OUR TEAM",
      "We look for talented individuals who share our commitment to sustainability and desire to make a positive impact. If you are passionate about ESG and looking for an opportunity to grow with a dynamic and supportive team, Nirantara might be the place for you. For more details, visit our Careers Page"

    ],
    keywords: ['Our Team']
  },
  {
    route: '/resources',
    page: 'Resources',
    sentences: [
      "Links (external) for resources",
      "Global Reporting Initiative",
      "GRI - Home (globalreporting.org)",
      "GRI - Download the Standards (globalreporting.org)",
      "Business Responsibility & Sustainability Reporting",
      "https://www.sebi.gov.in/sebi_data/commondocs/jul-2023/Annexure_I-Format-of-BRSR-Core_p.pdf",
      "https://www.sebi.gov.in/sebi_data/commondocs/jul-2023/Annexure_II-Updated-BRSR_p.PDF",
      "CDP",
      "Home - CDP",
      "Guidance for companies - CDP",
      "Supplier Assurance",
      "SUPPLIERASSURANCE",
      "Questionnaire (supplierassurance.com)",
      "GHG Protocol",
      "Homepage | GHG Protocol",
      "Corporate Standard | GHG Protocol",
      "Scope 2 Guidance | GHG Protocol",
      "Scope 3 Calculation Guidance | GHG Protocol",
      "SimaPro",
      "SimaPro | LCA software for informed changemakers",
      "Life Cycle Assessment (LCA) explained - PRé Sustainability (pre-sustainability.com)",
      "The Intergovernmental Panel on Climate Change (IPCC)",
      "IPCC — Intergovernmental Panel on Climate Change",
      "AR6 Synthesis Report: Climate Change 2023 — IPCC",
      "AR5 Synthesis Report: Climate Change 2014 — IPCC",
      "UNFCCC",
      "UNFCCC",
      "UN Climate Change Conference - United Arab Emirates | UNFCCC",
      "UN SDGs",
      "Home | Sustainable Development (un.org)",
      "Transforming our world: the 2030 Agenda for Sustainable Development | Department of Economic and Social Affairs (un.org)",
      "THE 17 GOALS | Sustainable Development (un.org)",
      "United Nations Global Compact (UNGC)",
      "Homepage | UN Global Compact",
      "The Ten Principles | UN Global Compact",
      "UN Global Compact Management Model | UN Global Compact",
      "Guide to Corporate Sustainability | UN Global Compact",
      "WWF",
      "WWF - Endangered Species Conservation | World Wildlife Fund",
      "Changing the Nature of Business | World Wildlife Fund",
      "Changing the Nature of Business | World Wildlife Fund",
      "Infrastructure | Initiatives | WWF (worldwildlife.org)",
    ],
    keywords: ['Resources']
  },
  {
    route: '/sustainability',
    page: 'Sustainability',
    sentences: [
      "Sustainability",
      "Discover how we can integrate environmental responsibility, social equity, and economic prosperity into every aspect of your operations. At Nirantara, we are committed to helping your organization to build a better future for our planet and its inhabitants. Sustainability isn't just a buzzword for us; it's a core value that guides everything we do.",
      "To us, Sustainability means being mindful of our environmental impact, treating our employees and communities with respect, and creating products and services that contribute positively to society. All this while focusing on a meaningful improvement in the organization's profitablity, consistently.",
      "Through simple but decisive steps towards reducing waste, conserving resources, and minimizing carbon footprint, to manufacturing processes, to the materials used, we strive to help you make responsible choices every step of the way.",
      "In our view, Sustainability is more than focusing on just environmental stewardship. It's also about social responsibility. We are dedicated to partner with you to foster an inclusive and diverse workplace where everyone has the opportunity to substantially contribute to the growth of your business as your stakeholders. We will walk you through viable initiatives to cater to your local communities.",
      "Ultimately, sustainability is about thinking long-term and acting responsibly today to ensure a brighter tomorrow and for generations to come. Allow us to join you on your journey towards a more sustainable future, one decision at a time. Together, we can make a difference."
    ],
    keywords: ['Our Team']
  },
  {
    route: '/esg',
    page: 'ESG',
    sentences: [
      "ESG",
      "(Environmental, Social, and Governance) factors are increasingly recognised as critical contributors to business success. By prioritising ESG considerations in your business, you can enhance long-term sustainability and resilience while also generating positive outcomes for stakeholders.",
      "Environmental initiatives such as reducing carbon emissions, minimising waste, and conserving resources not only mitigate risks associated with climate change but also lead to cost savings and operational efficiencies.",
      "Socially collaborative steps, including fostering diversity and inclusion, incorporating fair labor practices, and supporting community engagement, individually and collectively contribute to a positive corporate culture while enhancing brand reputation, and attracting top talent.",
      "Governance factors such as transparent reporting, ethical leadership, and effective risk management frameworks instill trust among investors, leading to improved access to capital and long-term shareholder value.",
      "In summary, integrating ESG considerations into your business practices is not only the right thing to do ethically but also delivers tangible benefits, driving innovation, competitiveness, and sustainable growth. We can be your sustainability partner for integrating ESG successfully into your business strategy.",

    ],
    keywords: ['ESG']
  },
  {
    route: '/integrated-approach',
    page: 'Integrated Approaches',
    sentences: [
      "Integrated Approache",
      "At Nirantara, we understand that Integrating Corporate Social Responsibility (CSR), Environmental, Social, and Governance (ESG) and the United Nations Sustainable Development Goals (UN SDGs) are not just parallel paths but interconnected strategies driving sustainable success. Integrating CSR, ESG criteria, and the UN SDGs into a coherent sustainability strategy is a comprehensive approach that requires nuanced understanding and strategic planning. As your sustainability partner, we can guide you through this integration process with precision to positively enhance your organization's impact on the planet while aligning with global standards and driving long-term value.",
     "Here is how we can help you with the integration",
     "Understanding the Frameworks and Standards ",
     "Assessment and Alignment",
     "Develop an Integration Strategy",
     "Develop a road map for integration, implementation and management",
     "Stakeholder engagement techniques",
     "Measurement and reporting",
     "Continuous evaluations for monitoring improvement and innovating",
     "At Nirantara, we aim to shift, the narrative from compliance to value creation by recognizing CSR and ESG as opportunities for creating long-term value. Together, we will identify opportunies for the use the UN SDGs as a framework to guide holistic effort while encouraging Innovation where viable to gain competitive advantages and open new markets.",
     "By effectively integrating CSR, ESG, and UN SDGs, as your sustainability partner, Nirantara can help your business not only navigate the complexities of sustainability but also position you as leaders in sustainable development, driving positive impact while achieving business success."
    ],
    keywords: ['Integration']
  },
  {
    route: '/climate-and-esg',
    page: 'Climate & ESG',
    sentences: [
      "Climate and ESG",
      "At Nirantara, we believe that addressing climate change is not just an environmental imperative but a strategic business opportunity. Our dedicated approach to Climate Action, embedded within our comprehensive Environmental, Social, and Governance (ESG) implementation services, reflects our commitment to guiding businesses towards sustainable and responsible growth. We strive to be pioneers in implementation of sustainable business practices while carefully navigating the critical intersection between ESG and Climate Action.",
      "Our services are designed to allow businesses to focus on market competitiveness throughout while seamlessly integrating the elements of ESG and sustainability into routine processes and creating future proofing measures simultaneously.",
      "Adopting Climate Action as a central component of your ESG strategy opens up numerous opportunities for innovation and growth. Sustainable practices can lead to cost savings through improved efficiency while creating positive value through enhancement of brand equity and customer loyalty. Moreover, businesses that proactively address climate risks are better positioned to navigate regulatory changes, attract ESG-focused investments, and secure a competitive advantage in the transition to a low-carbon economy.",
      "At Nirantara, our team is committed to providing tailored ESG consultancy services that empower your business to lead with sustainability at the forefront. By partnering with us, you gain access to cutting-edge insights, strategies, and tools for integrating Climate Action into your business operations and corporate ethos.",
      "Join us in transforming challenges into opportunities for sustainable growth. Together, we can pave the way for a more resilient business strategy and a prosperous future."
      ],
    keywords: ['Climate & ESG']
  },
  {
    route: '/contact-us',
    page: 'Contact',
    sentences: [
     "INDIA",
     "TAMIL NADU",
     "Nirantara Ecoventures Pvt Ltd",
     "New No.16 Old No.18, Chengalvarayan Street, Shenoy Nagar, Egmore Nungambakkam, Chennai- 600030, Tamil Nadu, India",
     "MAHARASHTRA",
     "Office no. 405, 4th Floor, Tower 2, World Trade Center, Kharadi, Pune – 411014, Maharashtra, India",
     "UNITED ARAB EMIRATES",
     "Nirantara Sustainable Consultancy LLC",
     "1513, Grosvenor Business Tower, Al Barsha Heights, Dubai, P.O.Box 454352 United Arab Emirates",
     "SINGAPORE",
     "UNITED ARAB EMIRATES",
     "UAE",
     "Nirantara Sustainable Ventures Pte Ltd",
     "30, Cecil Street, # 19-08, Prudential Tower Singapore (049712)",
     

      ],
    keywords: ['CONTACT', 'INDIA']
  },
  
];


function splitIntoChunks(sentence) {
  const chunks = [];
  const maxLength = 30;
  let startIndex = 0;

  while (startIndex < sentence.length) {
    let endIndex = startIndex + maxLength;
    while (endIndex < sentence.length && !/\s/.test(sentence.charAt(endIndex))) {
      endIndex++;
    }
    chunks.push(sentence.substring(startIndex, endIndex));
    startIndex = endIndex;
  }

  return chunks;
}

searchJson.forEach(item => {
  item.sentences = item.sentences.reduce((acc, sentence) => {
    const sentenceChunks = splitIntoChunks(sentence);
    return acc.concat(sentenceChunks);
  }, []);
});

export default searchJson;
