  import React from "react";
import Landing from "./Landing";
import Text from "./Text";
import Sustainbility from "./Sustainbility";
import ExplainVedio from "./ExplainVedio";

const Journey = () => {
  return (
    <div className="w-full  flex flex-col items-center gap-2 lg:gap-8a  helvetica ">
      <Landing />
      <Text />
      
      <Sustainbility />
    </div>
  );
};

export default Journey;
