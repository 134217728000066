import * as React from "react";
import { useLocation, useNavigate } from "react-router";
function DownloadResourcesButton() {
  return (
    <a href="/assets/Nirantara_Brochure.pdf" target="_blank" download className="justify-center self-start px-4 py-3.5 mt-5 text-sm leading-5 text-right bg-white border-2 border-white border-solid rounded-[60px] text-stone-900">
      Download Resources
    </a>
  );
}

function LinkRenderer({ link ,full_width }) {
  const navigate = useNavigate()
  const path = useLocation().pathname

  return (
    <li  >
      <span
        className={` cursor-pointer`}
        
        onClick={() => {
          if (link?.link) {
            navigate(link.link)
            console.log(link.link, path)
            if (path === link.link) {
              window.location.reload()
            }
          }
        }}
      >
        {link?.title}
      </span>
    </li>
  )
}
function AboutNirantara() {
  return (
    <div className="flex flex-col text-white max-md:mt-10 max-md:max-w-full">
      <h2 className="text-base font-bold leading-6 max-md:max-w-full text-white">
        About Nirantara
      </h2>
      <p className="mt-6 text-sm leading-7 max-md:max-w-full">
        
      Sustainability / ESG agendas are growing strong across the world today, as organisations seek innovative solutions to navigate this evolving landscape. Nirantara focuses on providing profitable approaches while empowering organisations in sustainability thinking, strategy, and practice. We are committed to providing cutting-edge solutions and actionable strategies to help businesses become sustainable and thrive, all while making a positive impact on the planet and society.
      </p>
      <div className="flex gap-8 mt-8 items-center">
        <a
          href="https://www.linkedin.com/company/nirantara-ecoventures/"
          target="_blank"
        >
          <img alt="twitter" src="/assets/icons/linkedin.svg" />
        </a>

        <a
          href="https://www.facebook.com/profile.php?id=61551552543080"
          target="_blank"
        >
          <img alt="facebook" src="/assets/icons/facebook.svg" />
        </a>

        <a
          href="https://instagram.com/nirantara_ecoventures?igshid=MzRlODBiNWFlZA=="
          target="_blank"
        >
          <img alt="instagram" src="/assets/icons/instagram.svg" />
        </a>
      </div>
    </div>
  );
}

function FooterLinks() {

  // const companyLinks = [
  //   "Sustainability",
  //   "ESG",
  //   "Integrated Approach",
  //   "Climate and ESG",
  // ];

  const companyLinks = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Sustainability",
      link: "/sustainability",
    },
    {
      title: "ESG",
      link: "/esg",
    },
    {
      title: "Integrated Approach",
      link: "/integrated-approach",
    },
    {
      title: "Climate and ESG",
      link: "/climate-and-esg",
    },
  ];

  // const menuLinks = [
  //   "Nirantara Journey",
  //   "Products",
  //   "Membership and Partnership",
  //   "Resources",
  //   "Careers",
  //   "Our Team",
  // ];

  const menuLinks = [
    {
      title: "Nirantara Journey",
      link: "/journey",
    },
    {
      title: "Products",
      link: "/products",
    },
    {
      title: "Memberships and Partnerships",
      link: "/membership-and-partnership",
    },
    {
      title: "Certification",
      link: "/certification",
    },
    {
      title: "Resources",
      link: "/resources",
    },
    {
      title: "Careers",
      link: "/career",
    },
    {
      title: "Our Team",
      link: "/our-team",
    },
  
  ];

  const connectLinks =[
    {
      title: "Connect with us",
      link: "/contact-us",
    }
  ]
  return (
    <div className="mt-6 max-md:max-w-full">
      <div className="flex justify-between max-md:flex-col max-md:gap-0">
        <div className="flex flex-col  max-md:ml-0 max-md:w-full">
          <ul className="flex flex-col text-sm leading-10 text-white   ">
            {companyLinks.map((link, index) => (
              <LinkRenderer link={link} full_width={false} key={index}/>
            ))}
          </ul>
        </div>
        <div className="flex flex-col   max-md:ml-0 max-md:w-full ">
          <ul className="full_width flex flex-col grow text-sm leading-10 text-white ">
            {menuLinks.map((link, index) => (
              <LinkRenderer link={link} full_width={true} key={index} />
            ))}
          </ul>
        </div>
        <div className="flex flex-col  max-md:ml-0 max-md:w-full">
          <ul  className="flex flex-col grow text-sm leading-10 text-white text-right">
            {connectLinks.map((link, index) => (
              <LinkRenderer link={link} full_width={false} key={index} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

function Footer() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center helvetica">
      <div className="flex flex-col items-center px-16 pt-20 pb-3.5 w-full bg-neutral-800 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col mt-2 w-full max-w-[1290px] max-md:max-w-full">
          <header className="flex gap-5 font-bold max-md:flex-wrap max-md:max-w-full">
            <h1 className="flex-auto text-3xl leading-10 text-white max-md:max-w-full">
              Towards a Sustainable Future, One Step at a Time
            </h1>
            <DownloadResourcesButton />
          </header>
          <hr className="shrink-0 mt-14 h-px border border-solid bg-zinc-200 border-zinc-200 max-md:mt-10 max-md:max-w-full" />
          <main className="mt-16 max-md:mt-10 max-md:max-w-full">
            <div className="flex justify-between gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full">
                <AboutNirantara />
              </div>
              <div className="flex flex-col ml-5 w-[43%] max-md:ml-0 max-md:w-full">
                <footer className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                  <div className=" w-full grid grid-cols-3 text-base font-bold leading-6 text-white max-md:flex-wrap max-md:max-w-full">
                    <div>Company</div>
                    <div className="ml-4">Menu</div>
                    <div className="text-right">Contact us</div>
                  </div>
                  <FooterLinks />
                </footer>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Footer;
