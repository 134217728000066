import * as React from "react";

function Text() {
  return (
    <article className="text-lg leading-9 text-black max-w-7xl px-2 flex flex-col gap-4 md:gap-8">
      <p>
        At Nirantara, we understand that Integrating Corporate Social Responsibility (CSR), Environmental, Social, and Governance (ESG) and the United Nations Sustainable Development Goals (UN SDGs) are not just parallel paths but interconnected strategies driving sustainable success. Integrating CSR, ESG criteria, and the UN SDGs into a coherent sustainability strategy is a comprehensive approach that requires nuanced understanding and strategic planning. As your sustainability partner, we can guide you through this integration process with precision to positively enhance your organization's impact on the planet while aligning with global standards and driving long-term value.
      </p>
      <p>Here is how we can help you with the integration:</p>
      <ul className="list-disc px-4 md:px-12">
        <li>Understanding the Frameworks and Standards</li>
        <li>Assessment and Alignment</li>
        <li>Develop an Integration Strategy</li>
        <li>Develop a road map for integration, implementation and management</li>
        <li>Stakeholder engagement techniques</li>
        <li>Measurement and reporting</li>
        <li>Continuous evaluations for monitoring improvement and innovating</li>
      </ul>
      <p>
        At Nirantara, we aim to shift, the narrative from compliance to value creation by recognizing CSR and ESG as opportunities for creating long-term value. Together, we will identify opportunies for the use the UN SDGs as a framework to guide holistic effort while encouraging Innovation where viable to gain competitive advantages and open new markets.
      </p>
      <p>
        By effectively integrating CSR, ESG, and UN SDGs, as your sustainability partner, Nirantara can help your business not only navigate the complexities of sustainability but also position you as leaders in sustainable development, driving positive impact while achieving business success.
      </p>
    </article>
  );
}

export default Text;