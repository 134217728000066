import * as React from "react";

const jobData = [
  // {
  //   index: 0, title: "ESG Manager",
  //   description:
  //     "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers.",
  // },
  // {
  //   index: 1,
  //   title: "Junior ESG Executive",
  //   description:
  //     "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers.",
  // },
  // {
  //   index: 2,
  //   title: "Marketing Executive",
  //   description:
  //     "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers.",
  // },
  // {
  //   index: 3,
  //   title: "Research Analyst",
  //   description:
  //     "Nirantara Internship Program is designed to offer aspiring professionals a unique opportunity to immerse themselves in the world of Environmental, Social, and Governance (ESG) consulting. This program aims to bridge the gap between academic knowledge and real-world application, providing hands-on experience in sustainability initiatives and ESG integration strategies. Here's a brief overview of what the program offers.",
  // },
  {
    index: 0,
    title: "Marketing & Sales Executive",
    description:
      "Nirantara is looking for motivated and talented Marketing & Sales Executives to join our growing team.Marketing & Sales Executives will be the front line for the Nirantara Sales Team and the first person a prospect will talk to.<br />A hunter persona, she/he will go out and hunt for new opportunities, qualifying prospects, and accounts to confirm fit & size.",
    location: "Pune, Nashik, Bhubaneshwar",
    position: "3"
  },
  {
    index: 1,
    title: "Management Trainee",
    description:
      "Are you passionate about making a real impact in sustainability industry? <br /> Freshers Welcome! <br />We’re on the lookout for dynamic Management Trainees with a background in Environmental Engineering, MSc Environmental Science, or a B.E. degree in any branch. <br />If you’re eager to dive into the world of carbon footprint analysis, life cycle assessments, and strategic market research, this opportunity is for you!.",
    location:"Bhubaneshwar, Aurangabad ", 
    position:"2"
  },
];



const Description4 = [

  {
    title: "Responsibilities",
    content: [
      "Create and maintain sales and marketing dashboards with each activity recorded and measured for accountability. ",
      "Liaise internally with stakeholders for all marketing including inputs for social media, research papers, proposals and collateral. ",
      "Work with CMO and other managers to coordinate daily sync-ups for progress of sales pipeline",
      "Create minutes of meetings, follow up and ensure timely execution across internal and external parties. ",
      "Organising the prospect database, finding more sources for the same and executing to next level with cold calls and emailers. ",
      "Stay updated with latest news and reports on ESG and sustainability across industries. ",
   
    ]
  },
  {
    title: "What we are looking for",
    content: [
      "BBA/MBA or similar education",
      "Environmental studies or sustainability experience preferred  ",
      "Great communication skills ",
      // "Proven ability to meet and exceed set targets- measured monthly, quarterly, and annually",
      // "Experience of gathering business relevant information and generating client interest through a combination of in-person client meetings, remote selling and tele-sales approach",
      // "Prior experience with CRM applications- viz. Salesforce, Zoho, etc.",
      // "Strong CRM discipline, the ability to accurately maintain and forecast 30-60-90 day pipeline",
      // "Ability to hustle, work with ambiguity, and actively participate in problem-solving"
    ]
  },
  {
    title: "Competencies   ( Must Have)",
    content: [
      "Extremely Meticulous and organised",
      "Business oriented",
      "Critical thinking  ",
      // "Ability to work under pressure and ensure on-time delivery",
      // "A persuasive communicator, who can connect with founders, CEOs, of small & medium enterprises",
      // "Exceptional interpersonal skills – ability to synthesize complex issues and communicate",
      // "Excellent command over written and spoken English",
      // "Responsible, reliable, and a keen interest in learning.",
      // "Need to be adept at interacting, communicating, and partnering with industry/account team and cross domain technical specialists"

    ]
  },
  {
    title: "Good to have",
    content: [
      "Self-driven",
      "Curious ",
      "Go-getter ",
      "Target oriented ",
      "Data driven ",
     
    ]
  },
//   {
//     title: "Benefits",
//     content: [
//       "Great work culture with a mix of senior and mid-management",
//       "Freedom and flexibility to navigate work, with focus on results attained ",
//       "Industry experts as mentors for great learning experiences.",
// "Career advancement across streams or vertically basis your ambition"
//     ]
//   },

];

const Description5 = [
  {
    title: "Responsibilities",
    content: [
      "To assist to collect the data from clients and verify as per the requirements. .",
      "Data collection, Validation, Verification & Calculation for Carbon footprint emission (scope 1, 2 & 3)",
      "Data collection, Validation, Verification & Calculation for Life Cycle Assessment.",
      "To assist to prepare the draft reports for Carbon Footprint, Life Cycle Assessment & ESG as per the guidelines and frameworks.",
      "As and when required, contribute to administrative tasks for organization. ",
      "Read up and understand ESG Concepts and the various Frameworks associated with it.",
      "Build a Database of Prospects ",
      "Manage Cold Calling and Warm Calling ",
      "Identify top leads and establish contact with potential customers.",
      "Create, maintain, and update the sales pipeline ",
      "Manage account mapping, buyer personas, identify the right people in target accounts and set up meetings for sales heads.",
      "Research sectors & companies in target markets to manage sales & spot opportunities.", 
      "Qualify accounts via direct/indirect validation before adding to the pipeline.",
      "Employ research skills and find information independently.",
      "Familiarise with the CRM applications of the company",
      "As and when required, contribute to administrative tasks for organization.",
      "This role will be performed onsite, and you are expected to work from office for the duration of your traineeship."
    ]
  },
  {
    title: "What we are looking for",
    content: [
      "Environmental Engineer or MSc Environmental Science or B.E. (any branch) or relevant educational background"

    ]
  },
  {
    title: "Competencies  ( Must have )",
    content: [
      "Excellent written and verbal communication.",
      "Proficiency in MS office, Sales CRM and marketing automation tools",
      "Ability to work under pressure and ensure on-time delivery",
      "Exceptional interpersonal skills",
      "Responsible, reliable, and keen interest in learning.",
      "Good understanding of and experience in all elements of the marketing mix",
      "Analytical mindset with the ability to interpret data, generate insights, and make data-driven decisions to optimize marketing performance.",
      "Ability to work effectively in a fast-paced, collaborative environment, manage multiple projects simultaneously, and meet deadlines.",
    ]
  },
  {
    title: "Good to have",
    content: [
      "Willingness to go beyond self to take on larger goals",
      "A persuasive communicator",
      "Good secondary research skills and ability to find information independently",
     
      "Excellent presentation skills- making ppts as well as delivery",

    ]
  },
  // {
  //   title: "Benefits",
  //   content: [
  //     "Great work culture with a mix of senior and mid-management.",
  //     "Freedom and flexibility to navigate work, with focus on results attained.",
  //     "Industry experts as mentors for great learning experiences.",
  //     "Career advancement across streams or vertically basis your ambition."
  //   ]
  // },

];


function JobCard({ index, title, description, location, position, details }) {
  const [showDescription, setShowDescription] = React.useState(false);

  const handleToggleDescription = () => {
    setShowDescription(!showDescription);
    document.body.style.overflow = showDescription ? 'auto' : 'hidden';
  };

  let jobDescription = index === 0 ? Description4 : index === 1 ? Description5 : [];

  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains('blur_popup')) {
      setShowDescription(false);
    }
  };

  const [activeIndex, setActiveIndex] = React.useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const isSectionActive = (index) => activeIndex === index;

  const getArrowIcon = (index) => (isSectionActive(index) ? '🢖' : '🢗');

  const handleDownload = (index) => {
    const map = {
      0: 'busDev',
      1: 'MarketSpec'
    };

    const filePath = process.env.PUBLIC_URL + `/assets/${map[index]}.docx`;
    const link = document.createElement('a');
    link.href = filePath;
    link.download = `${map[index]}.docx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <article className="flex flex-col grow p-8 w-full font-semibold bg-white rounded-lg shadow-lg max-md:px-5 max-md:mt-5 max-md:max-w-full">
      <h2 className="lg:text-3xl text-black capitalize max-md:max-w-full">
        {title}
      </h2>
      <div className="flex justify-between">
        <p className="font-medium text-black py-4">
          Location: <span className="font-light">{location}</span>
        </p>
        <p className="font-medium text-black py-4">
          Positions: <span className="font-light">{position}</span>
        </p>
      </div>
      {description && (
      <div className="text-lg leading-7 font-normal text-black max-md:max-w-full hidden_scrollbar h-80">
        {description.split('<br />').map((line, idx) => (
          <React.Fragment key={idx}>
            <p>{line}</p>
            {idx < description.split('<br />').length - 1 && (
              <div className="my-2"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    )}


      {details ? (
        <button
          onClick={handleToggleDescription}
          className="flex justify-center items-center gap-6 px-6 py-3 mt-6 max-w-full text-base text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-sm w-[168px] max-md:px-5"
        >
          {showDescription ? 'Close Description' : 'View Description'}
        </button>
      ) : (
        <a
          href="mailto:hr@nirantara.solutions"
          className="flex justify-center items-center gap-6 px-6 py-3 mt-6 max-w-full text-base text-center text-white capitalize whitespace-nowrap bg-lime-600 rounded-sm w-[148px] max-md:px-5"
        >
          <p>Apply</p>
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="16" viewBox="0 0 37 16" fill="none">
            <path
              d="M36.7071 8.70711C37.0976 8.31659 37.0976 7.68342 36.7071 7.2929L30.3431 0.928935C29.9526 0.53841 29.3195 0.53841 28.9289 0.928935C28.5384 1.31946 28.5384 1.95262 28.9289 2.34315L34.5858 8L28.9289 13.6569C28.5384 14.0474 28.5384 14.6805 28.9289 15.0711C29.3195 15.4616 29.9526 15.4616 30.3431 15.0711L36.7071 8.70711ZM-8.74228e-08 9L36 9L36 7L8.74228e-08 7L-8.74228e-08 9Z"
              fill="white"
            />
          </svg>
        </a>
      )}

      {details && showDescription && (
        <div
          onClick={handleBackgroundClick}
          className="blur_popup fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 lg:min-w-[600px]"
        >
          <div className="bg-white p-8 rounded-lg max-w-md lg:min-w-[700px] h-96 lg:min-h-[500px] overflow-auto">
            <p className="cursor-pointer decoration-lime-600" style={{ float: 'right' }} onClick={() => handleDownload(index)}>
              Download
            </p>
            <h3 className="text-2xl font-semibold mb-4 text-lime-600">Job Description</h3>
            <hr className="mb-4" />
            <div className="h-80 hidden_scrollbar">
              {jobDescription.map((section, index) => (
                <div key={index} className="border rounded">
                  <button
                    className="w-full text-left px-4 py-2 text-xl font-semibold focus:outline-none"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span>{section.title}</span>
                    <span
                      style={{ color: isSectionActive(index) ? '#82be2c' : '' }}
                      className="float-right"
                    >
                      {getArrowIcon(index)}
                    </span>
                  </button>
                  <div className={isSectionActive(index) ? 'block' : 'hidden'}>
                    {Array.isArray(section.content) ? (
                      <ul className="list-disc ml-6 mb-4">
                        {section.content.map((item, idx) => (
                          <li className="font-light" key={idx}>{item}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className="font-light px-4 py-2">{section.content}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </article>
  );
}


function JobOpenings() {
  return (
    <main className="px-4 md:px-20 flex flex-col lg:gap-12 w-full" >
      <h1 className="text-black lg:text-5xl font-semibold uppercase helvetica " >
        JOB OPENINGS
      </h1>
      <div className="flex flex-col  ">
        <section className="justify-center w-full max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {jobData.map((job, index) => (
              <div
                key={index}
                className={`flex flex-col w-6/12 max-md:ml-0 max-md:w-full ${index === 1 ? "ml-5" : ""
                  }`}
              >
                <JobCard index={index} title={job.title} description={job.description} location={job.location} position={job.position} details={true} />
              </div>
            ))}
          </div>
        </section>
        {/* <section className="justify-center mt-14 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {jobData.slice(2, 4).map((job, index) => (
              <div
                key={index}
                className={`flex flex-col w-6/12 max-md:ml-0 max-md:w-full ${index === 1 ? "ml-5" : ""
                  }`}
              >
                <JobCard title={job.title} />
              </div>
            ))}
          </div>
        </section>
        <section className="justify-center mt-14 w-full max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {jobData.slice(0, 2).map((job, index) => (
              <div
                key={index}
                className={`flex flex-col w-6/12 max-md:ml-0 max-md:w-full ${index === 1 ? "ml-5" : ""
                  }`}
              >
                <JobCard index={index} title={job.title} />
              </div>
            ))}
          </div>
        </section> */}
      </div>
    </main>

  );
}

export default JobOpenings;
