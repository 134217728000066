import * as React from "react";

function HeroSection() {
  return (
    <>
      <div className="self-center text-lg leading-9 text-center text-black max-md:max-w-full">
        In a world increasingly driven by digital innovation, Nirantara is set to redefine the landscape of corporate sustainability with its upcoming ESG Tech Platform, designed to encompass a holistic approach to managing and mitigating Scopes 1, 2, and 3 greenhouse gas (GHG) emissions. This innovative platform is crafted to empower businesses to not only navigate but also excel in their sustainability journey, ensuring they can effectively measure, analyze, and reduce their environmental footprint across all emission scopes.
      </div>
      <h2 className="self-center mt-32 text-5xl font-semibold text-center text-black uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        Platform Overview
      </h2>
      <div className="self-center mt-9 text-lg leading-9 text-center text-black max-md:max-w-full">
        Nirantara ESG Tech Platform is a comprehensive, cloud-based solution that enables businesses to measure, manage, and report their sustainability performance. By leveraging cutting-edge technology, including AI-driven analytics, blockchain for transparency, and IoT for real-time monitoring, the platform offers an unmatched toolkit for companies committed to making a genuine impact.
      </div>
    </>
  );
}

function ScopeSection({ title, description, imageUrl, isReversed }) {
  return (
    <div className="mt-32 w-full max-md:mt-10 max-md:max-w-full">
      <div className={`flex gap-5 max-md:flex-col max-md:gap-0 ${isReversed ? 'flex-row-reverse' : ''}`}>
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
            <h3 className="text-4xl font-bold text-black uppercase max-md:max-w-full">{title}</h3>
            <div className={`mt-9 text-lg ${isReversed ? "text-left" : "text-right"} leading-9 text-black max-md:max-w-full`}>{description}</div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <img src={imageUrl} alt="" className="grow w-full aspect-[1.2] max-md:mt-10 max-md:max-w-full" />
        </div>
      </div>
    </div>
  );
}

function CrossScopeSection() {
  return (
    <div className="mt-32 w-full text-left text-lg leading-9 text-black max-md:mt-10 max-md:max-w-full">
      <p>
        Cross-Scope Features for Comprehensive Sustainability Management
        <br />
        Data-Driven Insights: The platform uses AI and machine learning to provide actionable insights, helping businesses prioritize reduction efforts where they can have the greatest impact.
        <br />
        Reporting and Compliance: With built-in reporting tools, the platform simplifies compliance with global sustainability standards and regulations, making it easier for companies to communicate their ESG achievements.
        <br />
        Stakeholder Engagement: Through its stakeholder engagement module, the platform enhances transparency and fosters dialogue around sustainability efforts, building trust and alignment with investors, customers, and communities.
        <br />
        <br />
        Nirantara upcoming ESG Tech Platform is more than just a software solution; it represents a commitment to pioneering a sustainable future. By addressing Scopes 1, 2, and 3 emissions comprehensively, Nirantara ensures businesses can take a proactive and informed approach to their environmental impact. This platform is poised to become an indispensable tool for companies looking to lead in sustainability, demonstrating that effective environmental stewardship is within reach for businesses of all sizes and sectors.
      </p>
    </div>
  );
}

function TechPlatformExpansion() {
  const scopeData = [
    {
      title: 'Scope 1',
      description: 'Direct Emissions Management For Scope 1 emissions, which come directly from sources that are controlled by the company (such as emissions from combustion in owned or controlled boilers, furnaces, vehicles), the platform offers advanced monitoring tools. These tools enable real-time tracking of direct emissions, helping businesses identify high-emission areas and implement targeted reduction strategies. Integration with IoT devices provides precise data collection, facilitating informed decision-making and operational adjustments.',
      imageUrl: 'https://cdn.builder.io/api/v1/image/assets/TEMP/87b633eac76538d6d037ebdf659331726b58f30080473492483e4f5ace5229e5?apiKey=b3217365a35d45c196e47ed20ac8b87c&',
      isReversed: false,
    },
    {
      title: 'Scope 2',
      description: `Indirect Emissions from Purchased Electricity Addressing Scope 2 emissions, which are indirect emissions from the generation of purchased electricity consumed by the company, Nirantara platform includes features for tracking energy consumption patterns. It provides analytics for assessing the carbon footprint of electricity use and supports the transition to green energy sources. The platform aids companies in negotiating power purchase agreements (PPAs) for renewable energy and offers insights into leveraging renewable energy certificates (RECs) to offset Scope 2 emissions.`,
      imageUrl: 'https://cdn.builder.io/api/v1/image/assets/TEMP/7af46949930dba222107db4b21075aa6206eecb4c542970fc86b132a9245a62c?apiKey=b3217365a35d45c196e47ed20ac8b87c&',
      isReversed: true,
    },
    {
      title: 'Scope 3',
      description: `Value Chain Emissions Insight Perhaps the most challenging to manage, Scope 3 emissions include all other indirect emissions that occur in a company's value chain, from upstream activities like raw material extraction to downstream processes such as product use and end-of-life disposal. Nirantara platform offers comprehensive supply chain analysis tools, enabling businesses to map out and evaluate the carbon footprint of their entire value chain. By fostering collaboration with suppliers and customers through the platform, companies can encourage sustainable practices throughout their supply chain, driving industry-wide changes.`,
      imageUrl: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d75f4baeab2b29fdf35d40afe5b3435da69d3269e32a707343b7f060d27dce2d?apiKey=b3217365a35d45c196e47ed20ac8b87c&',
      isReversed: false,
    },
  ];

  return (
    <main className="flex flex-col md:mt-12 mt-4">
      <HeroSection />
      {scopeData.map((scope, index) => (
        <ScopeSection
          key={index}
          title={scope.title}
          description={scope.description}
          imageUrl={scope.imageUrl}
          isReversed={scope.isReversed}
        />
      ))}
      <CrossScopeSection />
    </main>
  );
}

export default TechPlatformExpansion;