import * as React from "react";
import DeliverablesExpansion from "./DeliverablesExpansion";
import CollapseButton from "./CollapseButton";

function Deliverables({expanded, setExpanded}) {
  const pageName = 'Deliverables'
  const isExpanded = expanded === pageName 
  const toggleExpand = () => {
    setExpanded(isExpanded ? null : pageName)
  }
  return (
    <section className="flex flex-col justify-center text-center text-white w-full">
      <div className="flex overflow-hidden relative flex-col justify-center items-end px-16 py-20 w-full min-h-[593px] max-md:px-5 max-md:max-w-full">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac5bb693ff97cd819c4079a3eac0f18a1dbbe1826a99ef2ea9381b3a8d29909a?apiKey=b3217365a35d45c196e47ed20ac8b87c&" alt="" className="object-cover absolute inset-0 size-full" />
        <div className="flex relative flex-col items-start py-11 pr-20 pl-10 mt-14 mb-8 max-w-full rounded-lg shadow-lg backdrop-blur-[400px] w-[600px] max-md:px-5 max-md:mt-10 max-md:mr-2.5">
          <h2 className="text-xl font-medium">02/ NIRANTARA PRODUCTS</h2>
          <h1 className="mt-8 lg:text-4xl font-bold uppercase">ESG DELIVERABLES</h1>
          <button onClick={toggleExpand} className="flex gap-5 justify-between px-6 py-3 mt-28 text-base text-black capitalize whitespace-nowrap bg-white rounded-sm max-md:px-5 max-md:mt-10">
            <span>{isExpanded ? 'Collapse' : 'Expand'}</span>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/c4ef6129d9caf0c52f01bf247782b1b5f8d13db5046bcba57abe96062c52498b?apiKey=b3217365a35d45c196e47ed20ac8b87c&" alt="" className="shrink-0 my-auto w-4 aspect-square" />
          </button>
        </div>
      </div>
      {
        isExpanded && 
          <>
            <DeliverablesExpansion />
            <CollapseButton onClick={toggleExpand} />
          </>
      }
    </section>
  );
}

export default Deliverables;