import * as React from "react";
import ExplainVedio from "./ExplainVedio";

function Intro() {
  return (
    <section className="w-full max-md:max-w-full text-lg leading-9 text-black">
      <p>
        Nirantara is the Sanskrit word which means "Continuous". Nirantara
        journey towards sustainability embodies the spirit of continuity in
        innovation, responsibility, and a deep commitment to create a
        "Continuous" balance between profitablity and the planet. Founded with
        the aim to integrate sustainability into the core of business practices,
        Nirantara has become a beacon of progress in the realm of environmental,
        social, and governance (ESG) principles. This brief write-up captures
        the essence of Nirantara sustainable journey.
      </p>
    </section>
  );
}

function SectionHeading({ children }) {
  return (
    <h2 className="mt-10 w-full text-5xl font-semibold text-black uppercase max-md:mt-10 max-md:max-w-full max-md:text-4xl">
      {children}
    </h2>
  );
}

function SectionContent({ children }) {
  return <section className="mt-9 text-lg w-full max-md:max-w-full leading-8 ">{children}</section>;
}

function Text() {
  return (
    <article className="mt-6 text-lg leading-9 text-black max-md:max-w-full px-4">
      <Intro />

      <SectionHeading>NIRANTARA vision</SectionHeading>
      <SectionContent>
        <p>
          Nirantara vision is to be the preferred partner for sustainable
          change, by offering a multitude of services and integrated solutions
          in a single window, to enable businesses and communities to prosper
          collaboratively.
        </p>
      </SectionContent>
      <ExplainVedio />

      <SectionHeading>NIRANTARA MISSION</SectionHeading>
      <SectionContent>
        <p>
          Nirantara mission is to demystify sustainability by making it
          accessible and feasible for enterprises across all business streams
          irrespective of the size. The Nirantara team will strive to promote
          awareness of sustainability frameworks while providing cost-effective
          solutions and services for enterprises to easily adopt sustainable
          practices in their operations. Nirantara aims to be a one-stop-shop
          solution provider for businesses across different spectrums who aim to
          achieve Net-Zero status and optimized social balance within the
          organization.
        </p>
      </SectionContent>

      <SectionHeading>NIRANTARA ETHOS</SectionHeading>
      <SectionContent>
        <p>
          Nirantara is founded on a set of core principles and values that guide
          its vision, mission, operations, and interactions with clients,
          communities, and the environment. The ethos of Nirantara outlines the
          commitment to sustainability, integrity, innovation, collaboration,
          and excellence. The principle pillars of Nirantara ethos are as
          follows:
        </p>
      </SectionContent>
    </article>
  );
}

export default Text